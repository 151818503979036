import {
    apiList,
    Api
} from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import sorttable from "@/components/sortTemplate.vue";
import paging from "@/components/pagings.vue";
// import { axios_file } from "@/assets/js/http_axios";
export default {
    inject: ["reload"],
    components: {
        // mytables,
        draggable,
        sorttable,
        paging,
    },
    data() {
        return {
            number1: "",
            SignForConfirmation: false,
            rules: false,
            cancel: false,
            ruleForm: {
                data1: ''
            },
            formLabelAlign: {
                storePackIds: [],
                storePackNum: [],
                dateTime: ''
            },
            activeName: 'first',
            queryData: {},
            options: [
                {
                    name: "a",
                    val: 0,
                },
                {
                    name: "b",
                    val: 1,
                },
            ],
            // ======公用组件==========
            pageNum: 1, //页码
            total: 0,
            sizeList: [10, 20, 50, 100, 200],
            size: 50, //一页几条
            pageNum1: 1, //页码
            total1: 0,
            size1: 50, //一页几条
            nextPage: false, //是否有下一页
            show_sortTableHeard: false, //显示弹窗
            show_sortTableHeard1: false, //显示弹窗
            show_upload: false, //显示上传弹窗
            myTableHeard: [], //我的表头
            originalTabelHeadeTitle: [],
            originalTabelHeadeTitle1: [],
            myTableHeard1: [], //我的表头
            selectLength: 0, //显示的长度
            selectLength1: 0, //显示的长度
            allCheck: false, //全选
            allCheck1: false, //全选
            tableHeigth: "50vh",
            tabelHeadeTitle: [
                // {
                //     name: "运单号",
                //     field: "channelNumber",
                //     width: "140",
                //     sort: 5,
                //     isShow: true,
                //     isTotal: false,
                //     isSort: true,
                // },
                {
                    name: "转运单号",
                    field: "courierNumber",
                    width: "140",
                    sort: 5,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "渠道代理",
                    field: "agentName",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "打包批次号",
                    field: "billPackNumber",
                    width: "180",
                    sort: 16,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "出库单号",
                    field: "storepackNumber",
                    width: "180",
                    sort: 16,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "业务单据类型",
                    field: "billPackTypeShow",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "仓库",
                    field: "storageName",
                    width: "80",
                    sort: 2,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "会员名称",
                    field: "nickname",
                    width: "120",
                    sort: 3,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "会员号",
                    field: "memberId",
                    width: "80",
                    sort: 4,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "收件人姓名",
                    field: "consigneeName",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "国家",
                    field: "chineseName",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "收货详细地址",
                    field: "addressee",
                    width: "180",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "货物品类",
                    field: "packageItem",
                    width: "80",
                    sort: 11,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "包裹件数",
                    field: "sumCount",
                    width: "80",
                    sort: 12,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "包裹重量(KG)",
                    field: "sumWeight",
                    width: "140",
                    sort: 13,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "包裹体积(m³)",
                    field: "volume",
                    width: "140",
                    sort: 14,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "长宽高(cm)",
                    field: "volumeDetail",
                    width: "100",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "包裹备注",
                    field: "comment",
                    width: "140",
                    sort: 15,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "打包时间",
                    field: "outstockTime",
                    width: "160",
                    sort: 22,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "打包人",
                    field: "outstockName",
                    width: "80",
                    sort: 22,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

            ],
            tabelHeadeTitle1: [
                // {
                //     name: "运单号",
                //     field: "channelNumber",
                //     width: "140",
                //     sort: 5,
                //     isShow: true,
                //     isTotal: false,
                //     isSort: true,
                // },
                {
                    name: "转运单号",
                    field: "courierNumber",
                    width: "140",
                    sort: 5,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "渠道代理",
                    field: "agentName",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "打包批次号",
                    field: "billPackNumber",
                    width: "180",
                    sort: 16,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "出库单号",
                    field: "storepackNumber",
                    width: "180",
                    sort: 16,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "业务单据类型",
                    field: "billPackTypeShow",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "仓库",
                    field: "storageName",
                    width: "80",
                    sort: 2,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "会员名称",
                    field: "nickname",
                    width: "120",
                    sort: 3,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "会员号",
                    field: "memberId",
                    width: "80",
                    sort: 4,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },


                {
                    name: "收件人姓名",
                    field: "consigneeName",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "国家/地区",
                    field: "chineseName",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "收货详细地址",
                    field: "addressee",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "货物品类",
                    field: "packageItem",
                    width: "80",
                    sort: 11,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "包裹件数",
                    field: "sumCount",
                    width: "80",
                    sort: 12,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "包裹重量(KG)",
                    field: "sumWeight",
                    width: "140",
                    sort: 13,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "包裹体积(m³)",
                    field: "volume",
                    width: "160",
                    sort: 14,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "长宽高(cm)",
                    field: "volumeDetail",
                    width: "100",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

                {
                    name: "包裹备注",
                    field: "comment",
                    width: "140",
                    sort: 15,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "打包时间",
                    field: "outstockTime",
                    width: "160",
                    sort: 22,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "打包人",
                    field: "outstockName",
                    width: "80",
                    sort: 22,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },

            ],
            tableData: [],
            tableData1: [],
            totalArr: [], //需要合计的字段
            totalArr1: [], //需要合计的字段
            className: "", //导出需要
            fieldName: [], //下载字段名
            head: [], //下载表头字段
            fileList: [],
            uploadUrl: "", //上传路径
            sortName: '模板名', //排序名
            sortName1: '模板名', //排序名
            pageGroupName: 'CustomerSignature2', //页面标识

            isActive: true, //输入框绑定动态class
            judge: false, //输入框绑定动态class
            MoreConditions: '更多条件', //输入框绑定动态class
            editData: [],  // 单条数据
            transportList: [],                  //  运输方式下拉菜单
            channelList: [],                    //  渠道方式下拉菜单
            agentIdArr: [],                     //  渠道代理
            storageArr: [],                     //  仓库下拉

            timeTypes: [ //  时间类型
                {
                    value: '0',
                    label: '申请打包时间'
                },
                {
                    value: '1',
                    label: '出库时间'
                },
                {
                    value: '2',
                    label: '打包确认时间'
                },
            ],
            timeType: '0',
            times: [], //  开始结束时间
            timeClass: {
                startBillPackTime: '', // 申请打包
                endBillPackTime: '',
                startOutstockTime: '',    // 出库时间
                endOutstockTime: '',
                startStockPackTime: '', // 打包确认
                endStockPackTime: '',
            },
            CurrentTime: [],
        };
    },
    updated() {
        if (this.show_sortTableHeard) {
            // 排序表头
            let arr = this.tabelHeadeTitle;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle = arr;
        }
        if (this.show_sortTableHeard1) {
            // 排序表头
            let arr = this.tabelHeadeTitle1;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle1 = arr;
        }
    },
    created() {
        this.uploadUrl = this.common.uploadUrl;
        if (this.originalTabelHeadeTitle.length == 0) {
            this.originalTabelHeadeTitle = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle)
            );
        }
        if (this.originalTabelHeadeTitle1.length == 0) {
            this.originalTabelHeadeTitle1 = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle1)
            );
        }
        this.getGroupFindFieldSort(); //获取用户自定义表头
        let endtime = tools.getCurrentDate(); //今天的时间;
        let sarttime = tools.getFlexDate(-10); //10天前
        this.times = [sarttime, endtime];
        this.timeClass.startBillPackTime = sarttime + ' 00:00:00';
        this.timeClass.endBillPackTime = endtime + ' 23:59:59';
    },
    mounted() {
        this.getData();
        this.getStorageIdArr()
        this.getChannelAgentChooseList()
        this.getChannelTransportIdArr()
        const that = this;

    },
    watch: {},
    methods: {

        rowclick(row, event, column) {
            this.$refs.mytable.toggleRowSelection(row);
        },
        rowclick2(row, event, column) {
            this.$refs.mytable1.toggleRowSelection(row);
        },
        // 选择时间类型
        timestatus(e) {
            this.timeType = e
            // timeClass
            if (this.CurrentTime.length > 0) {
                this.timeClass = {
                    startBillPackTime: '', // 申请打包
                    endBillPackTime: '',
                    startOutstockTime: '',    // 出库时间
                    endOutstockTime: '',
                    startStockPackTime: '', // 打包确认
                    endStockPackTime: '',
                }
                if (this.timeType == "0") {
                    // 申请打包
                    this.timeClass.startBillPackTime = this.CurrentTime[0] + ' 00:00:00';
                    this.timeClass.endBillPackTime = this.CurrentTime[1] + " 23:59:59";

                } else if (this.timeType == "1") {
                    // 出库时间
                    this.timeClass.startOutstockTime = this.CurrentTime[0] + ' 00:00:00';
                    this.timeClass.endOutstockTime = this.CurrentTime[1] + " 23:59:59";

                } else if (this.timeType == "2") {
                    // 打包确认
                    this.timeClass.startStockPackTime = this.CurrentTime[0] + ' 00:00:00';
                    this.timeClass.endStockPackTime = this.CurrentTime[1] + " 23:59:59";
                }
                this.getData();
            }

        },
        //时间选择
        timebut(e) {
            let val = e || [];
            this.CurrentTime = val;
            this.timeClass = {
                startBillPackTime: '', // 申请打包
                endBillPackTime: '',
                startOutstockTime: '',    // 出库时间
                endOutstockTime: '',
                startStockPackTime: '', // 打包确认
                endStockPackTime: '',
            }
            if (val.length < 1) {
                return false
            }

            if (this.timeType == "0") {
                // 申请打包
                this.timeClass.startBillPackTime = val[0];
                this.timeClass.endBillPackTime = val[1];

            } else if (this.timeType == "1") {
                // 出库时间
                this.timeClass.startOutstockTime = val[0];
                this.timeClass.endOutstockTime = val[1];

            } else if (this.timeType == "2") {
                // 打包确认
                this.timeClass.startStockPackTime = val[0];
                this.timeClass.endStockPackTime = val[1];
            }
            this.getData();
        },

        getChannelTransportIdArr() {           //  获取渠道和运输方式下拉
            Api.channelAndTransportList().then(res => {
                if (res.data.status === 'success') {
                    let obj = {}
                    this.transportList = res.data.result.reduce((cur, next) => {     //  去重
                        obj[next.channelTransportId] ? '' : obj[next.channelTransportId] = true && cur.push(next);
                        return cur
                    }, [])
                    this.channelAndTransportList = res.data.result || []
                } else {
                    this.$message.error(`${res.data.message}--渠道与运输方式下拉菜单`)
                }
            })
        },

        selectTId(val) {                                  //  选择运输方式下拉框时筛选渠道数据
            let arr = []
            this.queryData.channelId = ''
            this.channelAndTransportList.forEach(item => {
                if (item.channelTransportId === val) {
                    arr.push(item)
                }
            })
            this.channelList = arr
        },

        getChannelAgentChooseList() {           //  获取渠道代理
            Api.channelAgentChooseList().then(res => {
                if (res.data.status === 'success') {
                    this.agentIdArr = res.data.result || []
                } else {
                    this.$message.error(`${res.data.message}--渠道代理`)
                }
            })
        },
        getStorageIdArr() {
            Api.getStorageList().then((res) => {
                if (res.data.status === "success") {
                    this.storageArr = res.data.result || []
                    console.log(res.data.result)
                } else {
                    console.log(`${res.data.message}--仓库下拉`)
                }
            });
        },
        // 获取数据
        getData() {
            this.queryData.startBillPackTime = this.timeClass.startBillPackTime;
            this.queryData.endBillPackTime = this.timeClass.endBillPackTime;
            this.queryData.startOutstockTime = this.timeClass.startOutstockTime;
            this.queryData.endOutstockTime = this.timeClass.endOutstockTime;
            this.queryData.startStockPackTime = this.timeClass.startStockPackTime;
            this.queryData.endStockPackTime = this.timeClass.endStockPackTime;

            this.queryData.pageStart = this.pageNum
            this.queryData.pageTotal = this.size
            if (this.activeName === 'second') {
                this.queryData.isSignfor = 1
            } else {
                this.queryData.isSignfor = 0
            }
            Api.customerSign(this.queryData).then((res) => {
                if (res.data.status === "success") {
                    // this.className = res.data.result.className || "";
                    if (this.activeName === 'second') {
                        this.tableData1 = res.data.result.data || [];
                        this.total1 = res.data.result.pageCount || 0;
                    } else {
                        this.tableData = res.data.result.data || [];
                        this.total = res.data.result.pageCount || 0;
                    }
                    this.fetTableHeight();
                }
            });

        },

        //  输入框绑定动态class
        more() {
            this.isActive = !this.isActive
            this.judge = !this.judge
            this.fetTableHeight();
            if (this.judge === false) {
                this.MoreConditions = '更多条件'
            } else {
                this.MoreConditions = '收起条件'
            }
        },



        //  重置
        resetFeild() {
            this.number1 = ""
            this.queryData = {}
            this.times = []
            this.timeClass = {
                startBillPackTime: '', // 申请打包
                endBillPackTime: '',
                startOutstockTime: '',    // 出库时间
                endOutstockTime: '',
                startStockPackTime: '', // 打包确认
                endStockPackTime: '',
            }
        },

        //  顶部标签页切换
        handleClick() {
            this.number1 = ""
            this.queryData = {}
            if (this.activeName === 'second') {
                this.pageGroupName = 'CustomerSignature3'
                this.SignInRecord()
            } else {
                this.pageGroupName = 'CustomerSignature2'
                this.getGroupFindFieldSort()
            }
            this.getData()
        },


        // 刷新页面==>
        reload_s() {
            this.reload(); //App.Vue里面的方法
        },

        // 查询按钮
        queryBtn_ok() {
            this.pageNum = 1;
            let nidlist = new Array
            nidlist = tools.getOrderNum(this.number1)
            this.queryData.storepackNumber = nidlist
            if (this.active === 'second') {
                this.pageNum1 = 1;
            }
            this.getData();
        },

        //  签收确认
        qs() {
            if (this.editData.length !== 0) {
                //console.log(this.editData)
                let idArr = []
                this.editData.forEach(item => {
                    idArr.push(item.storepackId)
                })
                this.$confirm(`即将签收${this.editData.length}个包裹, 签收时间：${this.myGetTime()}?`, '提示', {
                    confirmButtonText: '签收确认',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    Api.signForConfirm({ storePackIds: idArr }).then((res) => {
                        if (res.data.status === "success") {
                            this.editData = []
                            this.getData()
                            this.$message.success("签收成功")
                        }
                    });
                })
            } else {
                this.editData = []
                this.$message.error('请选择一条数据')
            }

        },

        //  取消签收
        cancelSign() {
            if (this.editData.length == 1) {
                /*  let arr = []
                 let arrName = []
                 this.editData.forEach(item => {
                     arr.push(item.storepackId)
                     arrName.push(item.storepackNumber)
                 }) */
                let arr = []
                arr.push(this.editData[0].storepackId)
                this.formLabelAlign.storePackIds = arr
                this.formLabelAlign.dateTime = this.myGetTime()
                this.formLabelAlign.storePackNum = this.editData[0].billPackNumber
                this.cancel = true
            } else {
                this.$message.error('请选择一条数据')
            }
        },

        //  取消签收弹出层关闭的回调
        cancelSignC() {
            this.formLabelAlign = {
                storePackIds: [],
                storePackNum: [],
                dateTime: ''
            }
            this.cancel = false
        },

        //  确认取消签收
        cancelSignSub() {
            Api.cancelSignFor({ storePackIds: this.formLabelAlign.storePackIds }).then((res) => {
                if (res.data.status === "success") {
                    this.$message.success("取消签收成功")
                    this.editData = []
                    this.getData()
                    this.cancelSignC()
                }
            });
        },
        // 获取当前时间
        myGetTime() {
            let _date = ''
            let myDate = new Date();
            myDate.getFullYear();    //获取完整的年份
            myDate.getMonth() + 1;    //获取当前月份
            myDate.getDate();    //获取当前日(1-31)
            _date = `${myDate.getFullYear()}-${myDate.getMonth() + 1}-${myDate.getDate()}`
            return _date
        },
        // 提取快递单输入框的内容(无规则输入,定义了 换行,',' ,'空格三种')
        getStr(orderNos) {
            let arr = [];
            let arr_1 = [];
            if (orderNos.indexOf("\n") !== -1) {
                arr = orderNos.split("\n");
                //  ['123 344,dsg ewe','234 233','32,234']
                for (var i = 0; i < arr.length; i++) {
                    let arr_a = [];
                    if (arr[i].indexOf(",") !== -1) {
                        arr_a = arr[i].split(",");
                        arr_a.map((item) => {
                            arr_1.push(item);
                        });
                    } else {
                        if (arr[i]) {
                            arr_1.push(arr[i]);
                        }
                    }
                }
                setTimeout(() => {
                    for (var j = 0; j < arr_1.length; j++) {
                        let arr_b = [];
                        if (arr_1[j].indexOf(" ") !== -1) {
                            arr_b = arr_1[j].split(" ");
                            arr_b.map((item) => {
                                arr_b.push(item);
                            });
                        } else {
                            if (arr_1[j]) {
                                arr_b.push(arr_1[j]);
                            }
                        }
                    }
                }, 20);
            } else if (orderNos.indexOf(",") !== -1) {
                arr = orderNos.split(",");
                //  ['123 344','234','32 234']
                for (var b = 0; b < arr.length; b++) {
                    let arr_a = [];
                    if (arr[b].indexOf(" ") !== -1) {
                        arr_a = arr[b].split(" ");
                        arr_a.map((item) => {
                            arr_1.push(item);
                        });
                    } else {
                        if (arr[b]) {
                            arr_1.push(arr[b]);
                        }
                    }
                }
            } else if (orderNos.indexOf(" ") !== -1) {
                arr = orderNos.split(" ");
                arr.map((item) => {
                    if (item) {
                        arr_1.push(item);
                    }
                });
            } else {
                arr_1 = [orderNos];
            }
            let endArr = [];
            for (var q = 0; q < arr_1.length; q++) {
                if (arr_1[q].indexOf(" ") != -1) {
                    let arrs = arr_1[q].split(" ");
                    for (var w = 0; w < arrs.length; w++) {
                        if (arrs[w]) {
                            endArr.push(arrs[w]);
                        }
                    }
                } else {
                    if (arr_1[q]) {
                        endArr.push(arr_1[q]);
                    }
                }
            }
            return endArr;
        },

        //签收记录表头获取
        SignInRecord() {
            let that = this;
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        that.myTableHeard1 = JSON.parse(arr[0].sortObject);
                        this.sortName1 = arr[0].sortName;
                    }
                } else {
                    that.myTableHeard1 = that.tabelHeadeTitle1;

                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < that.myTableHeard1.length; i++) {
                    if (that.myTableHeard1[i].isTotal) {
                        totalArr.push(that.myTableHeard1[i].field);
                    }
                }
                that.totalArr1 = totalArr;
            });
            that.$nextTick(() => {
                that.$refs.mytable.doLayout();
            });
        },
        // 获取自定义的排序,没有就用默认的
        getGroupFindFieldSort() {
            let that = this;
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        that.myTableHeard = JSON.parse(arr[0].sortObject);
                        this.sortName = arr[0].sortName;
                    }
                } else {
                    that.myTableHeard = that.tabelHeadeTitle;

                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < that.myTableHeard.length; i++) {
                    if (that.myTableHeard[i].isTotal) {
                        totalArr.push(that.myTableHeard[i].field);
                    }
                }
                that.totalArr = totalArr;
            });

            that.$nextTick(() => {
                that.$refs.mytable.doLayout();
            });
        },
        // 设置模板按钮
        myModel() {
            let that = this;
            that.show_sortTableHeard = true;
            that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
            if (selectLength == that.tabelHeadeTitle.length) {
                that.allCheck = true;
            } else {
                that.allCheck = false;
            }
        },
        //设置模板按钮(签收记录)
        myModel1() {
            let that = this;
            that.show_sortTableHeard1 = true;
            that.tabelHeadeTitle1 = JSON.parse(JSON.stringify(that.myTableHeard1));
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength === that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },
        // 表格选择事件
        handleSelectionChange(e) {
            this.editData = e
        },
        // 排序==>取消按钮
        cancelSortHeard() {
            this.show_sortTableHeard = false;
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
        },
        // 排序==>确认按钮
        confirmOk(e) {
            this.tabelHeadeTitle = e;
            this.myTableHeard = this.tabelHeadeTitle;
            let sortObject = this.myTableHeard;
            let signs = [
                {
                    key: "sortName",
                    val: this.sortName, //模板名称
                },
                {
                    key: "groupName",
                    val: this.pageGroupName, //页面标识
                },
                {
                    key: "sortObject",
                    val: JSON.stringify(sortObject),
                },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            this.show_sortTableHeard = false;
        },
        // 点击某一项
        checktab(idx, e) {
            let that = this;
            that.tabelHeadeTitle[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
            if (selectLength == that.tabelHeadeTitle.length) {
                that.allCheck = true;
            } else {
                that.allCheck = false;
            }
        },
        //全选
        checkAll(e) {
            let that = this;
            this.allCheck = e;
            for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
                this.tabelHeadeTitle[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
        },
        //关闭弹窗
        closeDias(e) {
            this.show_sortTableHeard1 = e;
        },
        // 合计
        getSummaries(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr.length; i++) {
                    if (column.property == that.totalArr[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            return sums;
        },
        // 排序==>取消按钮(签收记录)
        cancelSortHeard1() {
            this.show_sortTableHeard1 = false;
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
        },
        // 排序==>确认按钮(签收记录)
        confirmOk1(e) {
            this.tabelHeadeTitle1 = e;
            this.myTableHeard1 = this.tabelHeadeTitle1;
            let sortObject = this.myTableHeard1;
            let signs = [
                {
                    key: "sortName",
                    val: this.sortName1, //模板名称
                },
                {
                    key: "groupName",
                    val: this.pageGroupName, //页面标识
                },
                {
                    key: "sortObject",
                    val: JSON.stringify(sortObject),
                },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName1, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard1 = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            this.show_sortTableHeard1 = false;
        },
        // 点击某一项
        checktab1(idx, e) {
            let that = this;
            that.tabelHeadeTitle1[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength == that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },
        //全选
        checkAll1(e) {
            let that = this;
            this.allCheck1 = e;
            for (var j = 0; j < this.tabelHeadeTitle1.length; j++) {
                this.tabelHeadeTitle1[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
        },
        // 合计
        getSummaries1(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr1.length; i++) {
                    if (column.property == that.totalArr1[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            return sums;
        },
        //关闭弹窗1
        closeDias1(e) {
            this.show_sortTableHeard1 = e;
        },
        //分页
        handleSizeChange(val) {
            this.size = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getData();
        },
        // 上一页
        handlePrevPage() {
            this.pageNum = this.pageNum - 1 || 1;
            this.getData();
        },
        // 下一页
        handleNextPage() {
            this.pageNum = this.pageNum + 1 || 1;
            this.getData();
        },

        // 点击某一项
        checktab1(idx, e) {
            let that = this;
            that.tabelHeadeTitle1[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength == that.tabelHeadeTitle.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },


        //  -----------  设置表格高度  -----------
        // 重置table高度
        resetHeight() {
            return new Promise((resolve, reject) => {
                this.tableHeigth = 0;
                resolve();
            });
        },
        // 设置table高度
        fetTableHeight() {
            this.resetHeight().then((res) => {
                this.tableHeigth = this.getHeight();
                this.$nextTick(() => {
                    this.$refs.mytable.doLayout();
                });
            });
        },
        // 获取表格高度
        getHeight() {
            let windonHeight = document.body.clientHeight
            let queryHeigth
            let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
            if (this.judge == true) {
                queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
                if (this.activeName === "second") {
                    queryHeigth = this.$refs.queryCriteria1.offsetHeight; //查询框
                }
            } else {
                queryHeigth = 50
            }
            let pagsHeigth = 60; //分页组件
            var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 100;
            return Math.floor(windonHeight - otherHeight) || "auto"; //
        },

        //  -----------  导出功能  -----------
        //  导出操作
        handleCommand_dc(command) {
            if (command == 'dc') {
                // 导出
                this.exportBtn()
            } else if (command == 'dcmb') {
                // 下载模板
                this.exportTemplateDownload()
            } else if (command == 'scmb') {
                // 上传模板
                this.upLoadBtn()
            }
        },

        // 上传导出模板
        uploadSectionFile(param) {
            const file = param.file,
                fileName = file.name;

            // 根据后台需求数据格式
            const form = new FormData();
            // 文件对象
            form.append("file", file);
            // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
            form.append("fileName", fileName);
            let signArr = [{
                key: "fileName",
                val: fileName,
            },];
            let sign = tools.getMD5Sign(signArr);
            form.append("sign", sign);
            // 项目封装的请求方法，下面做简单介绍
            Api.uploadFile(form)
                .then((res) => {
                    if (res.data.status == "success") {
                        let fileName = res.data.result.fileName;

                        let signArr = [{
                            key: "groupName",
                            val: this.pageGroupName,
                        },
                        {
                            key: "templateName",
                            val: this.sortName,
                        },
                        {
                            key: "fileName",
                            val: fileName,
                        },
                        ];
                        let sign_ = tools.getMD5Sign(signArr);
                        Api.saveExportTemplate({
                            groupName: this.pageGroupName,
                            templateName: this.sortName,
                            fileName: fileName,
                            sign: sign_,
                        }).then((res) => {
                            this.$message.success(res.data.message || "更新成功");
                            this.show_upload = false;
                        });
                    }
                })
                .catch((err) => {
                });
        },

        // 下载用户导出模板
        exportTemplateDownload() {
            var fieldName = this.fieldName;
            var head = this.head;
            if (head.length == 0) {
                let fieldNames = [];
                let heads = [];
                if (this.pageGroupName == 'CustomerSignature2') {
                    for (var i = 0; i < this.myTableHeard.length; i++) {
                        if (this.myTableHeard[i].isShow) {
                            fieldNames.push(this.myTableHeard[i].field);
                            heads.push(this.myTableHeard[i].name);
                        }
                    }
                }
                if (this.pageGroupName == 'CustomerSignature3') {
                    for (var i = 0; i < this.myTableHeard1.length; i++) {
                        if (this.myTableHeard1[i].isShow) {
                            fieldNames.push(this.myTableHeard1[i].field);
                            heads.push(this.myTableHeard1[i].name);
                        }
                    }
                }
                fieldName = fieldNames;
                head = heads;
            }
            //加密数组格式
            let signArr = [{
                key: "fieldName",
                val: fieldName,
            },
            {
                key: "head",
                val: head,
            },
            ];
            let sign = tools.getMD5Sign(signArr);
            let param = {
                fieldName: fieldName,
                head: head,
                sign: sign,
            };
            Api.exportTemplateDownload(param).then((res) => {
                let downloadurl = res.data.result.fileUrl || "";
                //下载(这里其实是直接访问)
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();
            });
        },
        // 真正导出
        templateDataExport(customizedExportId) {
            let that = this;
            let datas = []
            if (this.pageGroupName == 'CustomerSignature2') {
                datas = that.tableData;
            } else {
                datas = that.tableData1;
            }
            let parm = {
                customizedExportId: customizedExportId, //模板id
                className: that.className,
                data: datas,
            };
            let signarr = tools.getSignArr(parm);
            let signs_e = tools.getMD5Sign(signarr);
            Api.templateDataExport({
                customizedExportId: customizedExportId,
                className: that.className,
                data: JSON.stringify(datas),
                sign: signs_e,
            }).then((res) => {
                //下载(这里其实是直接访问)
                let downloadurl = res.data.result.fileUrl;
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();

            });
        },
        // 上传按钮
        upLoadBtn() {
            this.show_upload = true;
        },
        // 点击导出按钮
        exportBtn() {
            let that = this;
            let fieldName = [];
            let head = [];
            if (this.pageGroupName == 'CustomerSignature2') {
                for (var i = 0; i < this.myTableHeard.length; i++) {
                    if (this.myTableHeard[i].isShow) {
                        fieldName.push(this.myTableHeard[i].field);
                        head.push(this.myTableHeard[i].name);
                    }
                }
            }
            if (this.pageGroupName == 'CustomerSignature3') {
                for (var i = 0; i < this.myTableHeard1.length; i++) {
                    if (this.myTableHeard1[i].isShow) {
                        fieldName.push(this.myTableHeard1[i].field);
                        head.push(this.myTableHeard1[i].name);
                    }
                }
            }
            this.fieldName = fieldName;
            this.head = head;

            // 1,先获取用户导出模板
            Api.getUserExportTemplate({
                groupName: this.pageGroupName,
            }).then((res) => {
                if (res.data.result && res.data.result[0].customizedExportId) {
                    // 有自定义模板

                    let customizedExportId = res.data.result[0].customizedExportId;
                    that.templateDataExport(customizedExportId);


                } else {
                    if (this.pageGroupName == 'CustomerSignature2') {
                        tools.dynamicExport(
                            this.myTableHeard,
                            this.className,
                            this.tableData,
                            "客户签收列表"
                        );
                    } else {
                        tools.dynamicExport(
                            this.myTableHeard1,
                            this.className,
                            this.tableData1,
                            "客户签收记录列表"
                        );
                    }

                }
            });
        },
    },
};
